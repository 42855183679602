import { Injectable } from "@angular/core";
import { HttpClient, HttpContext } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { IAccountDetails, IScheduleDetailsList, IScheduleDetails } from "./toolbar.interface";
import { DISABLE_REQUEST_CANCELLATION_HANDLING, IRequestOptions } from "../common-services/httpCancel.service";
import { LANGUAGE_TYPE_BE } from "../common-services/common.enum";

@Injectable()

export class ToolbarService {
  private baseURL = environment.PLATFORM_ENDPOINT_API;

  constructor(private http: HttpClient) {

  }

  public changeAccount(accountId: number, userId: number): Observable<any> {
    return this.http.put(`${this.baseURL}users/change-account/${userId}`, { accountId });
  }

  public changeLanguage(userId: number, language: LANGUAGE_TYPE_BE): Observable<any> {
    const lang = {
      language: language.toUpperCase()
    };
    return this.http.put(`${this.baseURL}users/change-language/${userId}`, lang);
  }
  public get_account_detail(account_id: number): Observable<IAccountDetails> {
    return this.http.get<IAccountDetails>(`${this.baseURL}accounts/${account_id}`);
  }

  public update_account_name(account_id: number, account_name: string): Observable<any> {
    return this.http.put<any>(`${this.baseURL}accounts/name/${account_id}`, account_name);
  }

  public update_account_detail(account_id: number, account_detail: IAccountDetails): Observable<IAccountDetails> {
    return this.http.put<any>(`${this.baseURL}accounts/${account_id}`, account_detail);
  }

  public get_schedule_detail(account_id: number): Observable<IScheduleDetailsList> {
    return this.http.get<IScheduleDetailsList>(`${this.baseURL}schedules/${account_id}`);
  }

  public update_schedule_detail(account_id: number, schedule_detail: IScheduleDetails): Observable<IScheduleDetails> {
    return this.http.put<any>(`${this.baseURL}schedules/${account_id}/${schedule_detail.id}`, schedule_detail);
  }

  // Notification

  public mark_notification_read(user_id: number, notification_id: number): Observable<any> {
    return this.http.put(`${this.baseURL}notifications/${user_id}/markRead/${notification_id}`, "");
  }

  public mark_notification_unread(user_id: number, notification_id: number): Observable<any> {
    return this.http.put(`${this.baseURL}notifications/${user_id}/markUnread/${notification_id}`, "");
  }

  public get_all_notification(account_id: number, user_id: number, lang_key: string, is_sequence_req?: boolean): Observable<any> {
    if (is_sequence_req) {
      const req_options: IRequestOptions = {
        context: new HttpContext().set(DISABLE_REQUEST_CANCELLATION_HANDLING, true)
      };
      return this.http.get(`${this.baseURL}notifications/${user_id}/all/${account_id}/${lang_key}`, req_options);
    }
    return this.http.get(`${this.baseURL}notifications/${user_id}/all/${account_id}/${lang_key}`);
  }

  public get_archive_notification(account_id: number, lang_key: string, user_id: number): Observable<any> {
    return this.http.get(`${this.baseURL}notifications/archive/${account_id}/${lang_key}/${user_id}`);
  }

  public mark_read_all_notify(account_id: number, user_id: number): Observable<any> {
    return this.http.put(`${this.baseURL}notifications/${user_id}/markRead/all/${account_id}`, "");
  }
}
